<template>
  <!-- /** LOGO DTK LOGISTICA GPS */ -->
  <v-img
    class="my-2"
    style="
      background-color: rgba(255, 255, 255, 0);
      border-radius: 0px 18px 0px 18px;
      text-align: right;
      text-shadow: 0 0 3px #ff0000, 0 0 5px #0000ff;
    "
    max-width="110"
    align="center"
    justify="center"
    :src="require('@/assets/DTK.svg')"
  />
  <!-- /** LOGO DTK LOGISTICA GPS */ -->
</template>

<script>
// Utilities
import { get } from 'vuex-pathify';

export default {
  name: 'DefaultDrawerHeader',

  computed: { version: get('app/version') },
};
</script>
